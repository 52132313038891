.inspectionPhone{
    width: 100%;
    min-height: 100vh;
    height: auto;
    background: #F1F3F7;
    padding: 43.999px 24.998px;
    .title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .avatar{
            width: 120.002px;
            height: 120.002px;
        }
        div{
            display: flex;
            align-items: center;
            img{
                height: 34.999px;
                margin-right: 19.001px;
            }
            span{            
                font-weight: 400;
                font-size: 30.002px;
                color: #7D8FB3;
                cursor: pointer;
            }
        }
    }
    h1{ 
        margin-top: 22.997px;
        font-size: 56.002px;
        line-height: 72px;
        color: #0A1629;
    }
    .quest{
        cursor: pointer;
        padding: 30.002px 32.998px;
        background: white;
        border-radius: 10px;
        box-shadow: 0px 0px 4px rgba(51, 97, 255, 0.1);
        div{
            display: flex;
            justify-content: space-between;
            align-items: center;
            h3{
                font-weight: 500;
                font-size: 79.999px;
                line-height: 109.001px;
                color: #0A1629;
                margin: 0;
            }
            a{
                font-size: 60px;
                color: black;
            }
            img{
                width: 140.998px;
                height: 140.998px;
            }
        }
        ul{
            margin: 22.997px 0 0;
            display: flex;
            justify-content: space-between;
            p{
                margin: 0;
            }
            li{
                p{
                    &:first-child{
                        font-size: 23.998px;
                        line-height: 32.998px;
                        text-transform: capitalize;
                        color: #7D8FB3;
                    }
                    &:last-child{
                        font-weight: 500;
                        font-size: 36px;
                        line-height: 49.003px;
                        text-transform: capitalize;
                        color: #0A1629;
                        text-align: right;
                    }
                }
            }
        }
    }
    h2{
        font-weight: 500;
        font-size: 36px;
        line-height: 49.003px;
        color: #0A1629;
        margin-top: 55.001px;
    }
    .newInspectionsList{
        &>li{
            padding: 38.002px 22.997px 31.003px 39.002px;
            background: #FFFFFF;
            box-shadow: 0px 0px 4px rgba(51, 97, 255, 0.1);
            border-radius: 5px;
            margin-bottom: 15.998px;
            cursor: pointer;
            .no{
                display: flex;
                justify-content: space-between;
                align-items: center;
                h3{
                    font-weight: 400;
                    font-size: 36px;
                    line-height: 49.003px;
                    text-transform: capitalize;
                    color: #0A1629;
                    margin: 0;
                }
                &>div{
                    display: flex;
                    align-items: center;
                    span{
                        font-size: 23.998px;
                        line-height: 32.998px;
                        color: #7D8FB3;
                        margin-right: 18px;
                    }
                    div{
                        padding: 7.999px 15.998px;
                        border-radius: 4px;
                        font-size: 23.998px;
                        line-height: 29.002px;
                        text-transform: capitalize;
                        display: flex;
                        align-items: center;
                        &::before{
                            content: " ";
                            display: block;
                            width: 10.001px;
                            height: 10.001px;
                            border-radius: 50%;
                            margin-right: 10.001px;
                        }
                    }
                    .pending {
                        color: #3B82F6;
                        background: rgba(51, 97, 255, 0.1);

                        &::before {
                            background: #3B82F6;
                        }
                    }

                    .InProgress {
                        background: #91C561;
                        color: white;

                        &::before {
                            background: white;
                        }
                    }

                    .submitted {
                        background: #7D8FB3;
                        color: white;

                        &::before {
                            background: white;
                        }
                    }
                }
            }
            .type{
                display: flex;
                margin-top: 18px;
                li{
                    font-size: 23.998px;
                    line-height: 32.998px;
                    color: #7D8FB3;
                    margin-right: 28.001px;
                }
            }
        }
    }
    .more{
        text-align: center;
        color:#7D8FB3;
        font-size: 30.002px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
    }
}
@media screen and(max-width:800px) {
    .inspectionPhone{
        width: 100vw;
        height: auto;
        background: #F1F3F7;
        padding: 6.111vw 3.472vw;
        .title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .avatar{
                width: 16.667vw;
                height: 16.667vw;
            }
            div{
                display: flex;
                align-items: center;
                img{
                    height: 4.861vw;
                    margin-right: 2.639vw;
                }
                span{            
                    font-weight: 400;
                    font-size: 4.167vw;
                    color: #7D8FB3;
                }
            }
        }
        h1{ 
            margin-top: 3.194vw;
            font-size: 7.778vw;
            line-height: 10vw;
            color: #0A1629;
        }
        .quest{
            padding: 4.167vw 4.583vw;
            background: white;
            border-radius: 10px;
            box-shadow: 0px 0px 4px rgba(51, 97, 255, 0.1);
            div{
                display: flex;
                justify-content: space-between;
                align-items: center;
                h3{
                    font-weight: 500;  
                    font-size: 11.111vw;
                    line-height: 15.139vw;
                    color: #0A1629;
                    margin: 0;
                }
                a{
                    font-size: 5.089vw;
                    color: black;
                    display: none;
                }
                img{
                    width: 19.583vw;
                    height: 19.583vw;
                }
            }
            ul{
                margin: 3.194vw 0 0;
                display: flex;
                justify-content: space-between;
                p{
                    margin: 0;
                }
                li{
                    p{
                        &:first-child{
                            font-size: 3.333vw;
                            line-height: 4.583vw;
                            text-transform: capitalize;
                            color: #7D8FB3;
                        }
                        &:last-child{
                            font-weight: 500;
                            font-size: 5vw;
                            line-height: 6.806vw;
                            text-transform: capitalize;
                            color: #0A1629;
                            text-align: right;
                        }
                    }
                }
            }
        }
        h2{
            font-weight: 500;
            font-size: 5vw;
            line-height: 6.806vw;
            color: #0A1629;
            margin-top: 7.639vw;
        }
        .newInspectionsList{
            &>li{
                padding: 5.278vw 3.194vw 4.306vw 5.417vw;
                background: #FFFFFF;
                box-shadow: 0px 0px 4px rgba(51, 97, 255, 0.1);
                border-radius: 5px;
                margin-bottom: 2.222vw;
                .no{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    h3{
                        font-weight: 400;
                        font-size: 5vw;
                        line-height: 6.806vw;
                        text-transform: capitalize;
                        color: #0A1629;
                        margin: 0;
                    }
                    &>div{
                        display: flex;
                        align-items: center;
                        span{
                            font-size: 3.333vw;
                            line-height: 4.583vw;
                            color: #7D8FB3;
                            margin-right: 2.5vw;
                        }
                        div{
                            padding: 1.111vw 2.222vw;
                            border-radius: 4px;
                            font-size: 3.333vw;
                            line-height: 4.028vw;
                            text-transform: capitalize;
                            display: flex;
                            align-items: center;
                            &::before{
                                content: " ";
                                display: block;
                                width: 1.389vw;
                                height: 1.389vw;
                                border-radius: 50%;
                                margin-right: 1.389vw;
                            }
                        }
                        .pending {
                            color: #3B82F6;
                            background: rgba(51, 97, 255, 0.1);
    
                            &::before {
                                background: #3B82F6;
                            }
                        }
    
                        .InProgress {
                            background: #91C561;
                            color: white;
    
                            &::before {
                                background: white;
                            }
                        }
    
                        .submitted {
                            background: #7D8FB3;
                            color: white;
    
                            &::before {
                                background: white;
                            }
                        }
                    }
                }
                .type{
                    display: flex;
                    margin-top: 2.5vw;
                    li{
                        font-size: 3.333vw;
                        line-height: 4.583vw;
                        color: #7D8FB3;
                        margin-right: 3.889vw;
                    }
                }
            }
        }
        .more{
            text-align: center;
            color:#7D8FB3;
            font-size: 4.167vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}