.inspectionList {
    background: #F1F3F7;
    padding: 0 24.998px;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    .phone_header {
        text-align: center;
        padding: 25.999px 0 0;
        display: flex;
        font-size: 31.997px;
        justify-content: space-between;
        line-height: 43.999px;
        text-transform: capitalize;
        color: #0A1629;
        font-size: 30.002px;
    }

    .search {
        
        margin-top: 25.999px;
        .ant-input-search-button{
            height: 79.999px;
            width: 100px;
            background-color:#FFFFFF ;
        }
        svg{
            font-size: 24px;
        }
        input {
            width: 100%;
            height: 79.999px;
            border-radius: 5px;
            outline: none;
            // padding-left: 11.806vw;
            background-color: #FFFFFF;
            // background-image: url(../../images/navigation/search.png) ;
            background-size: 31.997px;
            background-repeat: no-repeat;
            background-position: 29.002px center;
            font-size: 24px;
            &::placeholder {
                font-size: 23.998px;
                line-height: 29.002px;
                color: #4D5E80;
            }

        }
    }

    .status {
        display: flex;
        justify-content: space-between;
        margin: 16.999px 0 0;
        li {
            width: 24%;
            padding: 23.998px 14.998px;
            font-size: 23.998px;
            line-height: 29.002px;
            color: #4D5E80;
            background: #E0E4EC;
            box-shadow: 0px 0px 0.556vw rgba(51, 97, 255, 0.1);
            border-radius: 4.997px;
            position: relative;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            span{
                // position: absolute;
                // right: 0;
                // top: 50%;
                // margin-top: 4.997px;
                // width: 100%;
                font-size: 23.998px;
                text-align: center;
                margin-left: 10px;
                // transform: translateY(-50%);
            }
        }

        .active {
            background: #5C7CEA;
            color: white;
        }
    }

    .newInspectionsList {
        margin-top: 15.998px;
        height: calc(100vh - 270px);
        overflow: auto;
        padding-bottom: 110px;

        .list_li {
            padding: 38.002px 22.997px 31.003px 39.002px;
            background: #FFFFFF;
            box-shadow: 0px 0px 4px rgba(51, 97, 255, 0.1);
            border-radius: 4.997px;
            margin-bottom: 15.998px;

            .no {
                display: flex;
                justify-content: space-between;
                align-items: center;

                h3 {
                    font-weight: 400;
                    font-size: 36px;
                    line-height: 49.003px;
                    text-transform: capitalize;
                    color: #0A1629;
                    margin: 0;
                }

                &>div {
                    display: flex;
                    align-items: center;

                    span {
                        font-size: 23.998px;
                        line-height: 32.998px;
                        color: #7D8FB3;
                        margin-right: 18px;
                    }

                    div {
                        padding: 7.999px 15.998px;
                        border-radius: 4px;
                        font-size: 23.998px;
                        line-height: 29.002px;
                        text-transform: capitalize;
                        display: flex;
                        align-items: center;

                        &::before {
                            content: " ";
                            display: block;
                            width: 10.001px;
                            height: 10.001px;
                            border-radius: 50%;
                            margin-right: 10.001px;
                        }
                    }

                    .pending {
                        color: #3B82F6;
                        background: rgba(51, 97, 255, 0.1);

                        &::before {
                            background: #3B82F6;
                        }
                    }

                    .InProgress {
                        background: #91C561;
                        color: white;
                        &::before {
                            background: white;
                        }
                    }

                    .submitted {
                        background: #7D8FB3;
                        color: white;
                        &::before {
                            background: white;
                        }
                    }
                    .Pass{
                        background: #91C5611F;
                        color: #91C561;
                        &::before {
                            background: #91C561;
                        }
                    }
                    .Fail{
                        background: #EA783C26;
                        color: #EA783C;
                        &::before {
                            background: #EA783C;
                        }
                    }
                }
            }

            .type {
                display: flex;
                margin-top: 18px;
                justify-content: space-between;
                li {
                    span{
                        margin-right: 3.889vw;
                    }
                    font-size: 23.998px;
                    line-height: 32.998px;
                    color: #7D8FB3;
                    // margin-right: 28.001px;
                }
            }
        }
        .more{
            text-align: center;
            color:#7D8FB3;
            font-size: 30.002px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}
@media screen and(max-width:780px) {
    .inspectionList {
        background: #F1F3F7;
        padding: 0 3.472vw;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
    
        .phone_header {
            text-align: center;
            padding: 3.611vw 0 0;
            display: flex;
            font-size: 4.444vw;
            justify-content: space-between;
            line-height: 6.111vw;
            text-transform: capitalize;
            color: #0A1629;
            font-size: 4.167vw;
        }
    
        .search {
            margin-top: 3.611vw;
            .ant-input-search-button{
                height: 11.111vw;
                width: 43px;
                background-color:#FFFFFF ;
            }
            input {
                width: 100%;
                height: 11.111vw;
                border-radius: 5px;
                outline: none;
                // padding-left: 11.806vw;
                background-color: #FFFFFF;
                // background-image: url(../../images/navigation/search.png) ;
                background-size: 4.444vw;
                background-repeat: no-repeat;
                background-position: 4.028vw center;
                font-size: 3.333vw;
                &::placeholder {
                    font-size: 3.333vw;
                    line-height: 4.028vw;
                    color: #4D5E80;
                }
    
            }
        }
    
        .status {
            display: flex;
            justify-content: space-between;
            margin: 2.361vw 0 0;
            li {
                padding: 3.333vw 2.083vw;
                font-size: 3.333vw;
                line-height: 4.028vw;
                color: #4D5E80;
                background: #E0E4EC;
                box-shadow: 0px 0px 4px rgba(51, 97, 255, 0.1);
                border-radius: 0.694vw;
                position: relative;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                span{
                    // position: absolute;
                    // right: 0;
                    // top: 50%;
                    margin-top: 0.694vw;
                    width: 100%;
                    font-size: 3.333vw;
                    text-align: center;
                    // transform: translateY(-50%);
                }
            }
    
            .active {
                background: #5C7CEA;
                color: white;
            }
        }
    
        .newInspectionsList {
            margin-top: 2.222vw;
            height: calc(100vh - 15.555vw);
            overflow: auto;
            padding-bottom: 110px;
    
            .list_li {
                padding: 5.278vw 3.194vw 4.306vw 5.417vw;
                background: #FFFFFF;
                box-shadow: 0px 0px 4px rgba(51, 97, 255, 0.1);
                border-radius: 0.694vw;
                margin-bottom: 2.222vw;
    
                .no {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
    
                    h3 {
                        font-weight: 400;
                        font-size: 5vw;
                        line-height: 6.806vw;
                        text-transform: capitalize;
                        color: #0A1629;
                        margin: 0;
                    }
    
                    &>div {
                        display: flex;
                        align-items: center;
    
                        span {
                            font-size: 3.333vw;
                            line-height: 4.583vw;
                            color: #7D8FB3;
                            margin-right: 2.5vw;
                        }
    
                        div {
                            padding: 1.111vw 2.222vw;
                            border-radius: 4px;
                            font-size: 3.333vw;
                            line-height: 4.028vw;
                            text-transform: capitalize;
                            display: flex;
                            align-items: center;
    
                            &::before {
                                content: " ";
                                display: block;
                                width: 1.389vw;
                                height: 1.389vw;
                                border-radius: 50%;
                                margin-right: 1.389vw;
                            }
                        }
    
                        .pending {
                            color: #3B82F6;
                            background: rgba(51, 97, 255, 0.1);
    
                            &::before {
                                background: #3B82F6;
                            }
                        }
    
                        .InProgress {
                            background: #91C561;
                            color: white;
    
                            &::before {
                                background: white;
                            }
                        }
    
                        .submitted {
                            background: #7D8FB3;
                            color: white;
    
                            &::before {
                                background: white;
                            }
                        }
                    }
                }
    
                .type {
                    display: flex;
                    margin-top: 2.5vw;
                    justify-content: space-between;
                    li {
                        span{
                            margin-right: 3.889vw;
                        }
                        font-size: 3.333vw;
                        line-height: 4.583vw;
                        color: #7D8FB3;
                    }
                }
            }
            .more{
                text-align: center;
                color:#7D8FB3;
                font-size: 4.167vw;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}