.AddPo {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    z-index: 10;
    // overflow: auto;
    form{
        height: calc(100vh - 64px);
        overflow: auto;
    }
    .head {
        padding: 17px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
            font-size: 16px;
            line-height: 22px;
            // text-transform: capitalize;
            color: #4D5E80;
        }
    }

    .generalInfo {
        display: flex;
        background: #E0E4EC;
        height: 48px;
        display: flex;
        justify-content: space-between;
        margin: 0;
        padding-right: 16px;
        li {
            display: flex;
            align-items: center;

            &:first-child {
                position: relative;

                div {
                    padding: 8px 16px;
                    line-height: 19px;
                    text-transform: capitalize;
                    border: 1px solid #D9D9D9;
                    border-bottom: none;
                    border-radius: 4px 4px 0 0;
                    position: absolute;
                    white-space: nowrap;
                    bottom: 0;
                    color: #4D5E80;
                    background: #D7DAE0;
                    cursor: pointer;

                    &:first-child {
                        left: 17px;
                    }

                    &:last-child {
                        left: 133px;
                    }
                }

                .active {
                    background: white;
                    color: #0A1629;
                }
            }

            &:last-child {
                .ant-btn {
                    border: none;
                    height: 36px;
                }
            }
        }
    }

    .data_entry {
        background: #F9FAFB;
        padding: 10px 16px 20px;
        display: flex;
        justify-content: space-between;

        .ant-select-selector,
        .ant-picker,
        .ant-input {
            border: 1px solid #E4E5E9;
            border-radius: 3px;
            font-weight: 400;
            font-size: 14px;
            // text-transform: capitalize;
            color: #4D5E80;
        }

        &>div {
            width: 49%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }

    .tableContent {
        padding: 12px 17px;

        h3 {
            font-size: 14px;
            line-height: 19px;
            // text-transform: capitalize;
            color: #0A1629;
        }

        .poTable {
            thead {
                tr {
                    th {
                        font-size: 12px;
                        line-height: 16px;
                        text-transform: capitalize;
                        color: #4D5E80;
                        background: #E0E4EC;
                        height: auto;
                        padding: 8px 15px;

                        &::before {
                            display: none;
                        }

                        &:first-child {
                            border-radius: 3px 0 0 0;
                        }

                        &:last-child {
                            border-radius: 0 3px 0 0;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        padding: 8px 15px;
                        font-size: 14px;
                        line-height: 19px;
                        text-transform: capitalize;
                        color: #4D5E80;
                        border-bottom: 1px solid #E4E5E9;

                        &:first-child {
                            border-left: 1px solid #E4E5E9;
                        }

                        &:last-child {
                            border-right: 1px solid #E4E5E9;
                        }
                    }
                }
            }
            .selected{
                background:yellow !important;
                .ant-table-cell{
                    background: yellow !important;
                }
            }
        }
    }
}
.uploadBox_{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    &>a{
        width: 95%;
    }
    &>span{
        width: 5%;
    }
    .ant-upload-list-item-actions{
        display: none;
    }
}
.ant-upload-list{
    max-height: 500px;
    overflow: auto;
}
.report {
    background: #FFFFFF;
    padding: 15px 20px;

    .reportTable {
        .ant-table-header {
            border-radius: 0;
        }

        thead {
            tr {
                th {
                    font-size: 14px;
                    line-height: 19px;
                    color: #FFFFFF;
                    text-transform: capitalize;
                    padding: 8px 16px;
                    background: #7D8FB3;
                    border-radius: 0 !important;
                    border: 1px solid #E4E5E9;

                    &::before {
                        display: none;
                    }
                }

                .ac {
                    background: #98C9A6;
                }

                .mi {
                    background: #E3BA7B;
                }

                .ma {
                    background: #E37B7B;
                }
            }
        }

        tbody {
            tr {
                td {
                    border: 1px solid #E4E5E9;
                }
            }
        }

        .comment {
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            color: #5C7CEA;
        }

        .reportTd {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .round {
                width: 24px;
                height: 24px;
                background: #FFFFFF;
                border: 1px solid #D9DDE0;
                border-radius: 50%;
                cursor: pointer;
            }
        }

        .detail1 {
            color: #4D5E80;
            font-weight: 800;
            font-size: 14px;
        }

        .detail2 {
            color: #4D5E80;
            font-weight: 400;
            font-size: 14px;
        }
    }
}