@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: Avenir-Black;
  src: url('../static/fonts/avenir-black.ttf');
}
@font-face {
  font-family: Avenir;
  src: url('../static/fonts/avenir-medium.ttf');
}
@font-face {
  font-family: Avenir-Book;
  src: url('../static/fonts/avenir-book.ttf');
}
@font-face {
  font-family: Avenir-light;
  src: url('../static/fonts/avenir-light.ttf');
}
* {
  margin: 0;
  padding: 0;
  border: none;
  list-style: none;
  font-family: "Avenir";
  text-decoration: none;
}
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,body{

font-style: normal;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.qcInput {
  height: 56px;
  border-radius: 5px;
}

.shadow-custom {
  box-shadow: 0px 0px 4px rgba(51, 97, 255, 0.1);
}

.ant-form-item-label {
  padding-bottom: 3px !important;
  font-size: 14px;
  color: #424C5B;
}

.ant-form-item {
  margin-bottom: 15px;
}
body {
  /* overflow: hidden; */
}