.pieviewBox{
    padding-bottom: 150px;
    .table_header{
        width: 770px;
        img{
            width: 250px;
        }
        p{
            text-align: center;
            font-size: 20px;
        }
    }
    h2{
        font-size: 16px;
        margin-top: 20px;
        font-weight: 800;
    }
    table{
        margin: 0;
        width: 770px;
        tr{
            td,th{
                padding: 10px;
                border:1px solid black;
            }
        }
    }
    .table_history tr td{
        text-align: center;
    }
    .table2{
        margin-top: 50px;
        tr{
            td{
                text-align: center;
                width: 25%;
            }
        }
    }
    .table3{
        margin-top: 50px;
        margin-bottom: 30px;
        tr{
            td{
                text-align: center;
                width: 30%;
            }
        }
    }
    .cancel_button{
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        background: white;
        padding: 26px;
        button{
            margin-right: 10px;
        }
    }
    .textBox{
        margin-top: 30px;
        h3{
            font-weight: 700;
            font-size: 16px;
        }
        h5{
            color: rgb(112,173,71);
            font-size: 20px;
            margin-top: 50px;
        }
        div{
            width: 770px;
            border: 1px solid black;
            padding: 20px;
            margin-bottom: 30px;
        }
        p{
            color:red;
            font-weight: 500;
        }
    }
    p.text{
        margin: 10px 0 0 0;
        font-weight: 700;
    }
    ul.text{
        width: 770px;
        display: flex;
        flex-wrap: wrap;
        li{
            width: 50%;
            margin-top: 30px;
            font-weight: 700;
            &:last-child{
                color: red;
            }
        }
    }
}