.newCheckList {
    .generalInfo {
        display: flex;
        background: #E0E4EC;
        height: 48px;
        display: flex;
        justify-content: space-between;
        margin: 0;
        padding-right: 16px;

        li {
            display: flex;
            align-items: center;

            &:first-child {
                position: relative;

                div {
                    padding: 8px 16px;
                    line-height: 19px;
                    text-transform: capitalize;
                    border: 1px solid #D9D9D9;
                    border-bottom: none;
                    border-radius: 4px 4px 0 0;
                    position: absolute;
                    white-space: nowrap;
                    bottom: 0;
                    color: #4D5E80;
                    background: #F9FAFB;
                    cursor: pointer;

                    &:first-child {
                        left: 17px;
                    }

                    &:last-child {
                        left: 133px;
                    }
                }

                // .active {
                //     background: white;
                //     color: #0A1629;
                // }
            }

            &:last-child {
                .ant-btn {
                    border: none;
                    height: 36px;
                }
            }
        }
    }

    .change_data {
        padding: 10px 15px 20px 33px;
        display: flex;
        justify-content: space-between;
        border: 1px solid #E4E5E9;
        border-top: 0;
        background: #F9FAFB;

        label {
            color: #4D5E80;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
        }

        .ant-input,
        .ant-select-selector {
            border-radius: 3px;
            border: 1px solid #E4E5E9;
            background: #FFF;
        }
    }

    .newCheckList_content {
        display: flex;
        justify-content: space-between;
        padding: 24px 32px;
        background: white;
        border: 1px solid #E4E5E9;
        border-top: 0;

        &>li {
            width: 49%;

            h3 {
                color: #4D5E80;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: capitalize;
                margin-bottom: 15px;
            }

            .checkboxdiv,.selectedBox {
                border-radius: 4px 4px 0px 0px;
                border: 1px solid #E4E5E9;
                background: #FFF;
                .select_all {
                    padding: 14px;
                    border-bottom: 1px solid #E4E5E9;
                    display: flex;
                    justify-content: space-between;
                    &>div:first-child{
                        display: flex;
                        align-items: center;
                    }
                }
                .templateList,.selected{
                    // &::-webkit-scrollbar {
                    //     width: 12px;
                    //     height: 12px;
                    // }
            
                    // &::-webkit-scrollbar-thumb {
                    //     background: #7D8FB3;
                    //     border: 1px solid white;
                    //     border-radius: 4px;
                    // }
            
                    // &::-webkit-scrollbar-track {
                    //     // border: 1px solid #E4E5E9;
                    //     border-radius: 2px;
                    //     background: #FFFFFF;
                    // }
            
                    // &::-webkit-scrollbar-button:horizontal:start {
                    //     width: 12px;
                    //     height: 12px;
                    //     background: url(../images/navigation/Angle_left.png) no-repeat center center;
                    // }
                    
                    // &::-webkit-scrollbar-button:horizontal:end {
                    //     width: 12px;
                    //     height: 12px;
                    //     background: url(../images/navigation/Angle_right.png) no-repeat center center;
                    // }
                    // &::-webkit-scrollbar-button:vertical:start {
                    //     width: 12px;
                    //     height: 12px;
                    //     background: url(../images/navigation/Angle_top.png) no-repeat center center;
                    // }
            
                    // &::-webkit-scrollbar-button:vertical:end {
                    //     width: 12px;
                    //     height: 12px;
                    //     rotate: 270deg;
                    //     background: url(../images/navigation/Angle_button.png) no-repeat center center;
                    // }
                    padding: 14px;
                    .checkbox_css{
                        color: #0A1629;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                    }
                    .checkbox_css_child{
                        color: #4D5E80;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                    }
                    .child_checkbox{
                        padding-left: 25px;
                    }
                    
                }
                .selected{
                    padding: 11px;
                    height: 500px;
                    overflow: auto;
                    .selectItem{
                        padding:  8px 45px;
                        background: url(../images/navigation/selectItem.png) no-repeat 12px 50%  #F3F4F8;
                        border-radius: 2px;
                        margin-bottom: 2px;
                        user-select: none;
                        // cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        span{
                            display: flex;
                            align-items: center;
                            margin-left: 8px;
                        }
                    }
                    .selectItemChild{
                        padding-left: 45px;
                        margin: 8px 0;
                        li{
                            color: #4D5E80;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 24px;
                            display: flex;
                            justify-content: space-between;
                            padding-right: 45px;
                        }
                    }
                }
            }
        }
    }
}