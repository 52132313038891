.uploadImgPopup{
    width: 90vw !important;
    height: 90vh !important;
    .uploadContent{
        padding: 36px 29px;
        border-top: 1px solid #E4E5E9;
        border-bottom: 1px solid #E4E5E9;
        background: #F9FAFB;
        .imageContent{
            .imageTitle{
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: 1px solid #E4E5E9;
                background: #F3F4F8;
                padding: 8px 11px;
                font-family: Avenir;
                h5{
                    color:  #7D8FB3;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: capitalize;
                }
                .buttonList{
                    display: flex;
                    button{
                        // height: 32px;
                        // line-height: 32px;
                        padding: 6px 16px;
                        color: #4D5E80;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        text-transform: capitalize;
                        display: flex;
                        align-items: center;
                        margin-left: 8px;
                        span{
                            line-height: 20px;
                            margin-left: 5px;
                        }
                    }
                }
                
            }
            .fileContent{
                min-height: 50vh;
                max-height: 60vh;
                overflow-y: auto;
                padding: 20px;
                border: 1px dashed #7D8FB3;
                background: #FFF;
                .imgBoxList{
                    // display: flex;
                    // flex-wrap: wrap;
                    // justify-content: flex-start;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr ;
                    position: relative;
                    gap:16px;
                    .dragText{
                        margin: 20vh auto 0;
                        text-align: center;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,0);
                        p{
                            text-align: center;
                            margin-bottom: 32px;
                            color: #7D8FB3;
                            font-family: Avenir;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            letter-spacing: 0.28px;
                        }
                        button{
                            padding: 6px 16px;
                            border-radius: 3px;
                            background: #91C561;
                        }
                    }
                    .imgBox{
                        width: 184px;
                        height: 184px;
                        position: relative;
                        // margin-right: 16px;
                        // margin-bottom: 16px;
                        transition: .5s all;
                        cursor: pointer;
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            -webkit-user-drag: none;
                        }
                        .removeImg{
                            position: absolute;
                            right:5px;
                            top: 5px;
                            font-size: 20px;
                            color: red;
                            cursor: pointer;
                        }
                    }
                }
                
            }
        }
    }
}
.viewImagePopup{
    width: 90vw !important;
    height: 90vh !important;
    .ant-modal-content{
        padding-bottom: 0;
    }
    .viewImagePopupContent{
        .imageTitle{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #E4E5E9;
            background: #F3F4F8;
            padding: 8px 25px;
            font-family: Avenir;
            h5{
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                margin-bottom: 0;
            }
            div:last-child{
                display: flex;
                align-items: center;
                button{
                    margin-left: 11px;
                    cursor: pointer;
                    &:last-child{
                        padding: 6px 16px;
                        line-height: 20px;
                    }
                }
            }
        }
        .img_content{
            overflow-y: auto;
            border-top: 1px solid #E4E5E9;
            border-bottom: 1px solid #E4E5E9;
            background: #F9FAFB;
            max-height: 60vh;
            height: 60vh;
            img{
                margin: 0 auto;
                display: block;
                height: 100%;
            }
        }
    }
    .img_footer{
        // height: 116px;
        background: white;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;
        li:first-child,li:last-child{
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
        li:first-child{
            box-shadow: 5px 0 10px rgba(0, 0, 0, 0.2)
        }
        li:last-child{
            box-shadow: -5px 0 10px rgba(0, 0, 0, 0.2)
        }
        li:nth-child(2){
            width: calc(100% - 120px);
            padding: 11px 20px;
            box-sizing: border-box;
            height: 100%;
            // margin: 0 20px;
            overflow-x: auto;
            .imageList{
                display: flex;
                margin: 0 auto;
                justify-content: center;
                // overflow: auto;
                div{
                    width: 96px;
                    height: 96px;
                    min-width: 96px;
                    margin-right: 16px;
                    cursor: pointer;
                    img{
                        object-fit: cover;
                        user-select: none;
                        width: 100%;
                        height: 100%;
                    }
                }
                .active{
                    border: 2px solid  #5C7CEA;
                }
            }
        }
    }
}
.ConfirmDelete{
    .ant-modal-content{
        .ant-modal-body{
            padding: 0 20px !important;
        }
        
    }
    
}
@media screen and(max-width:1800px) {
    .uploadImgPopup{
        .uploadContent{
            .imageContent{
                .fileContent{
                    .imgBoxList{
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                    }
                }
            }
        }
    }
}
@media screen and(max-width:1650px) {
    .uploadImgPopup{
        .uploadContent{
            .imageContent{
                .fileContent{
                    .imgBoxList{
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                    }
                }
            }
        }
    }
}
@media screen and(max-width:1420px) {
    .uploadImgPopup{
        .uploadContent{
            .imageContent{
                .fileContent{
                    .imgBoxList{
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                    }
                }
            }
        }
    }
}
@media screen and(max-width:1220px) {
    .uploadImgPopup{
        .uploadContent{
            .imageContent{
                .fileContent{
                    .imgBoxList{
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                    }
                }
            }
        }
    }
}