.qcStatus_default,
.qcStatus_fail,
.qcStatus_pending,
.qcStatus_pass {
    padding: 4px 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;

    .round {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 10px;
    }

    span {
        font-size: 12px;
    }
}
.ant-image{
    width: 100%;
}
.qcStatus_default {
    background: #EDF2F6;

    .round {
        background: #9DA5BE;
    }

    span {
        color: #9DA5BE;
    }
}

.qcStatus_fail {
    background: rgba(234, 120, 60, 0.15);

    .round {
        background: #EA783C;
    }

    span {
        color: #E96F2F;
    }
}

.qcStatus_pending {
    background: rgba(51, 97, 255, 0.1);

    .round {
        background: #3B82F6;
    }

    span {
        color: #3B82F6;
    }
}

.qcStatus_pass {
    background: rgba(145, 197, 97, 0.12);

    .round {
        background: #91C561;

    }

    span {
        color: #91C561;
    }
}
.page-content {
    border-radius: 4px;
    box-shadow: 0px 0px 4px rgba(51, 97, 255, 0.1);
    width: 100%;
    margin-top: 11px;
    height: 100%;
    min-height: 80vh;
    overflow: auto;
    padding: 31px 26px 0;
    background: white;
}
&::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}
&::-webkit-scrollbar-thumb {
    background: #7D8FB3;
    border: 1px solid white;
    border-radius: 4px;
}

&::-webkit-scrollbar-track {
    // border: 1px solid #E4E5E9;
    border-radius: 2px;
    background: #FFFFFF;
}

&::-webkit-scrollbar-button:horizontal:start {
    width: 12px;
    height: 12px;
    background: url(../images/navigation/Angle_left.png) no-repeat center center;
}

&::-webkit-scrollbar-button:horizontal:end {
    width: 12px;
    height: 12px;
    background: url(../images/navigation/Angle_right.png) no-repeat center center;
}
&::-webkit-scrollbar-button:vertical:start {
    width: 12px;
    height: 12px;
    background: url(../images/navigation/Angle_top.png) no-repeat center center;
}

&::-webkit-scrollbar-button:vertical:end {
    width: 12px;
    height: 12px;
    background: url(../images/navigation/Angle_button.png) no-repeat center center;
}
@media screen and(max-width:766px) {
    
    .phone_header {
        text-align: center;
        padding: 3.611vw 0 0;
        display: flex;
        font-size: 4.444vw;
        justify-content: space-between;
        line-height: 6.111vw;
        text-transform: capitalize;
        color: #0A1629;
    }

    .inspectionProgress {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        .triangle {
            width: 0;
            height: 0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-bottom: 30px solid #FFFFFF;
            position: absolute;
            top: 18.917vw;
            z-index: 99;
            left: 50%;
            transform: translateX(-50%);
          }
        .mask {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, .3);
            z-index: 9;
        }

        .inspectionProgress_content {
            position: absolute;
            top: 22.917vw;
            left: 50%;
            transform: translateX(-50%);
            width: 91.389vw;
            border-radius: 1.389vw;
            background: #FFFFFF;
            padding: 0 6.944vw 4.444vw 7.639vw;
            z-index: 10;
            h3 {
                font-weight: 500;
                font-size: 3.333vw;
                line-height: 4.583vw;
                text-transform: capitalize;
                color: #7D8FB3;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 10.972vw 0 5.417vw;

                span {
                    font-size: 4.444vw;
                    line-height: 6.111vw;
                    color: #4D5E80;
                    margin-left: 2.222vw;
                }
            }
            .procedure {
                display: flex;
                flex-wrap: wrap;
                max-height: 50vh;
                overflow: auto;
                li {
                    background: #FFF;
                    border: 1px solid #7D8FB3;
                    border-radius: 0.694vw;
                    font-size: 3.333vw;
                    color: #4D5E80;
                    width: 8.889vw;
                    height: 8.889vw;
                    line-height: 8.889vw;
                    text-align: center;
                    margin-right: 0.694vw;
                    margin-bottom: 0.694vw;
                }
                .NA{
                    border: 1px solid #7D8FB3;
                    background: #7D8FB3;
                    color: white
                }
                .AC{
                    border: 1px solid #91C561;
                    background: #91C561;
                    color: white
                }
                .PD{
                    border: 1px solid #3B9CF6;
                    background: #3B9CF6;
                    color: white
                }
                .MI{
                    border: 1px solid #F2A21A;
                    background: #F2A21A;
                    color: white
                }
                .MA{
                    border: 1px solid #E37B7B;
                    background: #E37B7B;
                    color: white
                }
            }
            .description{
                display: flex;
                .complete,.incomplete{
                    display: flex;
                    align-items: center;
                    margin-right: 2.778vw;
                    align-items: center;
                    span{
                        font-size: 2.778vw;
                        color: #4D5E80;
                        margin-right: 5px;
                    }
                    div{
                        width: 3.333vw;
                        height: 3.333vw;
                        border: 1px solid #7D8FB3;
                        border-radius: 0.694vw;
                        // margin-left: 1.667vw;
                    }
                }
                // .complete{
                //     margin-right: 4.861vw;
                //     div{
                //         background: #E0E4EC;
                //     }
                // }
                .NA{
                    margin-right: 2.778vw;
                    div{
                        background: #7D8FB3;
                    } 
                }
                .AC{
                    margin-right: 2.778vw;
                        div{
                            background: #91C561;
                        }
                }
                .PD{
                    margin-right: 2.778vw;
                        div{
                            background: #3B9CF6;
                        }
                }
                .MI{
                    margin-right: 2.778vw;
                    div{
                        background: #F2A21A;
                    }
                }
                .MA{
                    margin-right: 2.778vw;
                        div{
                            background: #E37B7B;
                        }
                }
            }
            .close{
                width: 7.222vw;
                height: 7.222vw;
                position: absolute;
                bottom: -13.889vw;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}