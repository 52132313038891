.search {
    border-radius: 4px;

    input,
    button {
        background: #EAEDF1;
        border-radius: 4px;
    }

}

.new {
    margin-right: 5px;
}

.vendor {
    margin-right: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #667182;
}

.status {
    margin: 0 10px 0 18px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #667182;
}

.select {
    .ant-select-selector {
        background: #EAEDF1 !important;
        border-radius: 4px;
        // margin-left: 10px;
    }

}

.page-content {
    border-radius: 4px;
    box-shadow: 0px 0px 4px rgba(51, 97, 255, 0.1);
    width: 100%;
    margin-top: 11px;
    height: 100%;
    min-height: 80vh;
    overflow: auto;
    padding: 31px 26px 0;

    .qc-table {
        font-size: 14px;

        thead {
            tr {

                th,
                td {
                    line-height: 19px;
                    padding-bottom: 10px;
                    background: none;
                    border: none;
                    border-bottom: 1px solid #4D5E80;
                    color: #4D5E80;
                    font-family: "Avenir-light";

                    &::before {
                        display: none;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    font-weight: 400;
                    font-size: 14px;
                    color: #4D5E80;
                }
            }
        }

        .action {
            display: flex;
            align-items: center;

            img {
                margin-right: 8px;
                cursor: pointer;
            }
        }
    }
}



.Add_Attributes {
    width: 620px !important;
}

.ant-modal {
    .modal_content {
        background: #F9FAFB;
        border: none;
        border-top: 1px solid #E4E5E9;
        border-bottom: 1px solid #E4E5E9;
        padding: 30px 18px;
    }

    .ant-modal-title {
        padding-left: 18px;
    }

    .ant-modal-content {
        padding: 40px 0;
        border-radius: 0;
    }

    .ant-form-item {
        margin-bottom: 15px;
    }

    .ant-modal-footer {
        padding-right: 18px;
    }

    .ant-form-item-label,
    h3 {
        padding-bottom: 3px;
        font-size: 14px;
        color: #424C5B;
    }

    .ant-btn {
        padding: 8px 16px;
        font-weight: 500;
        font-size: 14px;
        border-radius: 4px;
        height: auto;
    }

    .Assign_to_Inspector {
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        border-radius: 3px;
        height: 80px;
        padding: 7px;
    }
    .Assign_to_Inspector2 {
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        border-radius: 3px;
        min-height: 48px;
        padding: 7px;
    }

    .qcTag {
        background: #DDEFFF;
        border-radius: 4px;
        height: 28px;
        display: flex;
        align-items: center;
    }

    .InspectorError {
        color: #ff4d4f;
        margin-bottom: 0;
        transition: .5s all;
        font-size: 14px;
    }

    .type_status {
        display: flex;
        justify-content: space-between;
    }
}

.reports-div {
    flex-wrap: wrap;
}

@media screen and(max-width:700px) {
    .reports-div {
        div:first-child {
            width: 100%;
        }

        div:last-child {
            display: flex;
            flex-direction: column;

            &>span:first-child {
                margin: 10px 0;
            }
        }
    }
}