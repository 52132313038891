.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-between;
}

.login .login_left {
  width: 50%;
  background-color: #4D5E80;
  position: relative;
  padding-left: 10%;
}

.login .login_left .content {
  margin-top: 50%;
  width: 520px;
}

.login .login_left .content h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  text-transform: capitalize;
  color: #F3F4F8;
}

.login .login_left .content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #F3F4F8;
}

.login .login_left .content .text {
  margin: 83px 0 200px;
}

.login .login_left .circleW {
  position: absolute;
  bottom: 52px;
  right: 0;
}

.login .login_right {
  width: 50%;
  background: white;
  padding-left: 10%;
  position: relative;
}

.login .login_right .circleB {
  position: absolute;
  right: 0;
  top: 12px;
}

.login .login_right .content {
  width: 500px;
  margin-top: 29%;
}

.login .login_right .content .logo {
  margin-bottom: 85px;
}

.login .login_right .content .loginButton {
  width: 100%;
  height: 56px;
  font-weight: 600;
  font-size: 20px;
  color: #FFFFFF;
  background: #5C7CEA;
}
.login {
  .login_right {
    position: relative;
      h3 {
        display: none;
      }
    }
}
@media screen and (max-width:1400px){
  .login{
    .login_left{
      padding-left: 5%;
      .content{
        margin-top: 25%;
      }
    }
    .login_right{
      padding-left: 5%;
    }
  }
}
@media screen and (max-width:768px) {
  .login {
    .login_left {
      display: none;
    }

    .login_right {
      width: 100%;
      padding: 0 9%;
      h3{
        display: initial;
        position: absolute;
        width: 100%;
        font-weight: 400;
        font-size: 2.222vw;
        line-height: 3.056vw;
        color: #7D8FB3;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
        padding: 0 20px;
        text-align: center;
      }
      .content {
        width: 100%;
        margin-top: 27.778vw;

        .logo {
          position: relative;
          z-index: 1;
          display: block;
          margin: 0 auto 13.889vw;
        }

        .circleB {
          width: 34%;
        }
      }
    }
  }
}