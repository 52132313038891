.inspectionDetail {
    background: #F1F3F7;
    padding: 0 4.444vw;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    .inspectionDetail_content {
        margin-top: 5.833vw;
        padding: 4.444vw 3.611vw 7.639vw;
        background: #FFFFFF;
        box-shadow: 0px 0px 4px rgba(51, 97, 255, 0.1);
        border-radius: 1.389vw;

        ul {
            display: flex;
            flex-wrap: wrap;

            li {
                width: 50%;
                margin-bottom: 6.806vw;

                .label {
                    font-size: 3.333vw;
                    line-height: 4.583vw;
                    text-transform: capitalize;
                    color: #7D8FB3;
                    margin-bottom: 1.25vw;
                }

                p {
                    font-size: 4.167vw;
                    line-height: 5.694vw;
                    text-transform: capitalize;
                    color: #0A1629;
                    margin-bottom: 0;
                }

                input {
                    width: 100%;
                    height: 11.111vw;
                    background: #FFFFFF;
                    border: 1px solid #3361FF;
                    border-radius: 0.694vw;
                    padding-left: 4.306vw;
                    font-size: 4.167vw;
                    color: #3361FF;
                    outline: none;
                }
                .onStatus{
                    padding: 1.111vw 2.222vw;
                    border-radius: 4px;
                    font-size: 3.333vw;
                    line-height: 4.028vw;
                    text-transform: capitalize;
                    display: flex;
                    align-items: center;
                    width: 20.139vw;
                    &::before{
                        content: " ";
                        display: block;
                        width: 1.389vw;
                        height: 1.389vw;
                        border-radius: 50%;
                        margin-right: 1.389vw;
                    }
                }
                .pending{
                    color: #3B82F6;
                    background: rgba(51, 97, 255, 0.1);
                    &::before{
                        background: #3B82F6;
                    }
                }
                .InProgress{
                    background: #91C561;
                    color: white;
                    width: 25.139vw;
                    &::before{
                        background: white;
                    }
                }
                .submitted{
                    background: #7D8FB3;
                    color: white;
                    width: 23.139vw;
                    &::before{
                        background: white;
                    }
                }
            }
        }
    }

    button {
        width: 100%;
        font-size: 4.444vw;
        border-radius: 0.694vw;
        height: 13.333vw;
        line-height: 13.333vw;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 4.028vw;
    }
}