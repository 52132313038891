.ant-dropdown-menu {
    border-radius: 0 !important;
    padding: 0 !important;
}

.dropdown_ {
    font-size: 4.167vw;
    line-height: 5vw;
    color: #4D5E80;
    display: flex;
    align-items: center;

    img {
        width: 4.167vw;
        margin-right: 2.778vw;
    }
}

@media screen and (max-width:766px) {
    .ant-dropdown-menu-item {
        padding: 3.889vw 5.556vw 4.444vw !important;
        border-bottom: 1px solid #E0E4EC;
    }
}

.inspectionReportPhone {
    background: #F1F3F6;
    height: 100vh;
    width: 100vw;
    padding: 0 4.444vw;

    .content_ {
        overflow: auto;
        height: calc(100vh - 26.111vw);

        .attributeTitle {
            margin: 20px 0;

            h3 {
                font-size: 4.167vw;
                color: #0A1629;
            }
        }
    }

    .content_::-webkit-scrollbar {
        display: none;
    }

    .progress {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3.472vw;

        li {

            span,
            p {
                text-align: center;
            }

            span {
                font-size: 2.222vw;
                line-height: 3.056vw;
                color: #7D8FB3;
            }

            p {
                margin-top: 0.556vw;
                font-size: 3.333vw;
                line-height: 4.583vw;
                color: #4D5E80;
                margin-bottom: 0;
            }
        }

        li:nth-child(2) {
            width: 66.111vw;
            margin: 0 4.444vw;

            span {
                height: 3.056vw;
                display: inline-block;
            }

            .progress_bar {
                width: 100%;
                height: 4.444vw;
                background: #FFFFFF;
                box-shadow: 0px 0px 4px rgba(51, 97, 255, 0.1);
                border-radius: 2.222vw;
                position: relative;
                overflow: hidden;

                div {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    background: #91C561;
                    top: 0;
                    left: 0;
                }
            }
        }

    }

    .required {
        color: red;
        margin-right: 3px;
    }

    .issueBox {
        margin-top: 2.778vw;
        border-radius: 0.694vw;
        padding: 3.333vw;
        background: white;
        border-radius: 1.389vw;

        // border: 1px solid #7D8FB3;
        h3 {
            font-size: 2.222vw;
        }

        .issue {
            width: 100%;

            li {
                display: flex;
                padding: 5px 0;

                &:nth-child(odd) {
                    // justify-content: center;
                    color: #7D8FB3;
                    font-size: 3.333vw;
                    font-style: normal;
                    font-weight: 500;

                }

                &:nth-child(even) {
                    justify-content: space-between;

                    label {
                        width: 47%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        &>span {
                            display: inline-block;
                            margin-bottom: 3px;
                            font-size: 14px;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }

    .comment {
        padding: 3.333vw;
        background: white;
        border-radius: 1.389vw;
        margin-top: 2.778vw
    }

    .warn {
        padding: 1.389vw 0 1.389vw 1.806vw;
        background: rgba(239, 75, 36, 0.15);
        border-radius: 0.694vw;
        display: flex;
        align-items: center;
        margin-top: 3.194vw;

        img {
            width: 3.472vw;
            margin-right: 2.5vw;
        }

        span {

            font-size: 2.778vw;
            line-height: 3.611vw;
            color: #EF4B24;
        }
    }

    .report_phone_content {
        background: #FFFFFF;
        box-shadow: 0px 0px 4px rgba(51, 97, 255, 0.1);
        border-radius: 1.389vw;
        padding: 5.833vw 3.333vw;

        // height: calc(100% - 10% - 26.111vw);
        // overflow: auto;
        h5 {
            font-weight: 400;
            font-size: 3.333vw;
            line-height: 4.583vw;
            color: #4D5E80;
        }

        h1 {
            margin-bottom: 2.5vw;
        }

        h2 {
            margin-bottom: 7.5vw;
            font-size: 5vw;
        }

        img {
            width: 100%;
        }

        .firstTitle {
            font-weight: 500;
            line-height: 6.806vw;
            color: #0A1629;
            font-size: 5vw;
        }

        .secondTitle {
            color: #4D5E80;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            font-size: 4.583vw;
        }

        .selectCategory {
            width: 100%;
            margin-bottom: 10px;

            .ant-select-selector {
                border-radius: 3px;
                height: 40px;
            }

            .ant-select-selection-search-input {
                height: 40px;
            }

            .ant-select-selection-item,
            .ant-select-selection-placeholder,
            input {
                line-height: 40px;
            }
        }

        // h2 {
        //     font-weight: 500;
        //     font-size: 5vw;
        //     line-height: 6.806vw;
        //     color: #0A1629;
        // }

        .report_status {
            // margin-top: 14.583vw;
            display: flex;
            justify-content: space-between;

            li {
                background: #FFFFFF;
                border: 1px solid #4D5E80;
                border-radius: 0.694vw;
                font-size: 3.056vw;
                // line-height: 32.998px;
                padding: 2.778vw 5.556vw;
                color: #4D5E80;
            }

            // li {
            //     background: #FFFFFF;
            //     border: 1px solid #4D5E80;
            //     border-radius: 0.694vw;
            //     font-size: 3.333vw;
            //     line-height: 4.583vw;
            //     padding: 3.333vw 7.639vw;
            //     color: #4D5E80;
            // }

            .active0 {
                color: white;
                background: #7D8FB3;
                border: 1px solid #7D8FB3;
            }

            .active1 {
                color: white;
                background: #98C9A6;
                border: 1px solid #98C9A6;
            }

            .active2 {
                color: white;
                background: #F2A21A;
                border: 1px solid #F2A21A;
            }

            .active3 {
                color: white;
                background: #E37B7B;
                border: 1px solid #E37B7B;
            }

            .active4 {
                color: white;
                border: 1px solid #3B82F6;
                background: #3B82F6;
            }
        }

        .Functional_Check {
            margin-bottom: 6.111vw;

            ul {
                margin: 0;
            }

            .desc {
                display: flex;
                margin-bottom: 1.111vw;

                li {
                    padding: 2.778vw 3.194vw;
                    font-size: 4.167vw;
                    width: 50%;

                    &:first-child {
                        border-radius: 0.694vw 0px 0px 0.694vw;
                        font-weight: 500;
                        color: #0A1629;
                        border: 1px solid #7D8FB3;
                        background: #E0E4EC;
                    }

                    &:last-child {
                        font-weight: 400;
                        color: #3361FF;
                        border: 1px solid #3361FF;
                        border-radius: 0px 0.694vw 0.694vw 0px;
                        border-left: none;
                    }
                }
            }

            .statue_ {
                display: flex;
                justify-content: space-between;

                li {
                    background: #FFFFFF;
                    border: 1px solid #4D5E80;
                    border-radius: 0.694vw;
                    font-size: 3.333vw;
                    line-height: 4.583vw;
                    padding: 3.333vw 7.639vw;
                    color: #4D5E80;
                }

                .active0 {
                    color: white;
                    background: #7D8FB3;
                    border: 1px solid #7D8FB3;
                }

                .active1 {
                    color: white;
                    background: #91C561;
                    border: 1px solid #91C561;
                }

                .active2 {
                    color: white;
                    background: #F2A21A;
                    border: 1px solid #F2A21A;
                }

                .active3 {
                    color: white;
                    background: #E37B7B;
                    border: 1px solid #E37B7B;
                }
            }

        }

        .ConditionalPass {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 10.694vw;
            background: #FFFFFF;
            border: 1px solid #4D5E80;
            border-radius: 0.694vw;
            width: 50%;
            margin-bottom: 14px;
            img {
                margin-right: 1.528vw;
                width: 3.333vw;
                height: 3.333vw;
            }
        }

        .ConditionalPassActive {
            background: #91C561;
            border-color: #91C561;
            color: white;
        }

        .Inspection_Result {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1.389vw;
            li {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 10.694vw;
                background: #FFFFFF;
                border: 1px solid #4D5E80;
                border-radius: 0.694vw;
                width: 49%;

                img {
                    margin-right: 1.528vw;
                    width: 3.333vw;
                    height: 3.333vw;
                }

                &:first-child {
                    width: 34.7%;
                }

                &:nth-child(2) {
                    width: 31.9%;
                }

                &:last-child {
                    width: 30.7%;
                }
            }

            .active0 {
                background: #91C561;
                border-color: #91C561;
                color: white;
            }

            .active1 {
                background: #3B82F6;
                border-color: #3B82F6;
                color: white;
            }

            .active2 {
                background: #EB4444;
                border-color: #EB4444;
                color: white;
            }
        }

        h3 {
            font-size: 3.333vw;
            line-height: 4.583vw;
            text-transform: capitalize;
            color: #7D8FB3;
        }

        textarea {
            border-color: #7D8FB3;
            font-size: 3.333vw;
            line-height: 4.028vw;
            color: #3361FF;
            height: 27.778vw;
        }

        .task_photos {
            background: #FFFFFF;
            padding: 2.083vw;
            border: 1px solid #7D8FB3;
            border-radius: 5px;
            // min-height: 130px;
            .ant-upload-list-item-container {
                width: 13.333vw !important;
                height: 13.333vw !important;
                position: relative;

                .uploadBox {
                    width: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    height: 100%;

                    .ant-upload-list-item::before {
                        width: 100%;
                        height: 100%;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .delete_icon {
                    background: white;
                    position: absolute;
                    top: -2px;
                    right: -5px;
                    border-radius: 50%;
                    color: red;
                }
            }

            .ant-upload-list-item {
                margin-right: 1.111vw;
                border: none;
                padding: 0;
            }

            .ant-upload-select {
                width: 13.333vw !important;
                height: 13.333vw !important;
                border-radius: 0;

                img {
                    width: 3.889vw;
                }
            }

        }
    }

    .prev_next {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 4.167vw;
        height: 10%;

        button {
            width: 43.056vw;
            height: 13.333vw;
            font-size: 4.444vw;
            border-radius: 0.694vw;

            &:nth-child(1) {
                background-image: url(../../images/navigation/arrow_left.png);
                background-repeat: no-repeat;
                background-position: 10.833vw 50%;
                background-size: 1.806vw 2.778vw;
            }

            &:nth-child(2) {
                background-image: url(../../images/navigation/arrow_right.png);
                background-repeat: no-repeat;
                background-position: 31.25vw 50%;
                background-size: 1.806vw 2.778vw;
            }
        }
    }

    .save_submit {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        button {
            font-size: 4.444vw;
            border-radius: 0.694vw;
            font-weight: 400;
            font-size: 4.444vw;

            &:first-child {
                width: 100%;
                height: 13.889vw;
                margin-bottom: 2.778vw;
            }

            &:nth-child(2) {
                background-image: url(../../images/navigation/arrow_left.png);
                background-repeat: no-repeat;
                background-position: 10.833vw 50%;
                background-size: 1.806vw 2.778vw;
            }

            &:nth-child(n+2) {
                width: 43.056vw;
                height: 13.333vw;
                font-size: 4.444vw;
                border-radius: 0.694vw;
                color: #4D5E80;
            }
        }
    }
}

.phone_failure_code {

    .ant-checkbox,
    .ant-checkbox-inner {
        width: 20px !important;
        height: 20px !important;
    }

    .ant-checkbox-wrapper {
        margin-bottom: 2.222vw;
    }

    .ant-checkbox-inner::after {
        left: 29%;
    }

    textarea {
        height: 16.389vw !important;
    }

    li {
        margin-bottom: 3.889vw;
    }
}

.Imagebody {
    position: relative;
    overflow: hidden;

    .prev {
        position: absolute;
        left: 10px;
        top: 40%;
        z-index: 10;
    }

    .next {
        position: absolute;
        right: 10px;
        top: 40%;
        z-index: 10;
    }

    .rotate_save {
        display: flex;
        justify-content: space-between;
        background: white;
        padding: 2.778vw 2.778vw 0;
        position: relative;
        z-index: 10;
    }
}
.imgProgress{
    position: absolute;
    z-index: 9999;
    left: 50%;
    top: 50%;
    max-height: 100%;
    transform: translate(-50%,-50%);
}