.inspectionReport {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    z-index: 10;

    .head {
        padding: 17px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
            font-size: 16px;
            line-height: 22px;
            text-transform: capitalize;
            color: #4D5E80;
        }
    }

    form {
        height: calc(100vh - 116px);
        overflow: auto;
    }

    .pieviewBox_ {
        .table2 {
            margin-top: 50px;
            tr {
                td {
                    padding: 10px;
                    border: 1px solid #E4E5E9;
                    text-align: center;
                    width: 25%;
                    color: #4D5E80;
                    font-weight: 800;
                    font-size: 14px;
                }
            }
        }
    }

    .generalInfo {
        display: flex;
        background: #E0E4EC;
        height: 48px;
        display: flex;
        justify-content: space-between;
        margin: 0;

        li {
            display: flex;
            align-items: center;

            &:first-child {
                position: relative;

                div {
                    padding: 8px 16px;
                    line-height: 19px;
                    text-transform: capitalize;
                    border: 1px solid #D9D9D9;
                    border-bottom: none;
                    border-radius: 4px 4px 0 0;
                    position: absolute;
                    white-space: nowrap;
                    bottom: 0;
                    color: #4D5E80;
                    background: #D7DAE0;
                    cursor: pointer;

                    &:first-child {
                        left: 17px;
                    }

                    &:last-child {
                        left: 133px;
                    }
                }

                .active {
                    background: white;
                    color: #0A1629;
                }
            }

            &:last-child {
                .ant-btn {
                    border: none;
                    height: 36px;
                }
            }
        }
    }

    .data_entry {
        background: #F9FAFB;
        padding: 10px 16px 20px;
        // display: flex;
        // justify-content: space-between;
        display: grid;
        gap:15px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        .ant-select-selector,
        .ant-picker,
        .ant-input {
            // border: 1px solid #E4E5E9;
            border-radius: 3px;
        }
        .ant-form-item {
            margin-bottom: 0;
          }

        // &>div {
        //     // width: 49%;
        //     display: flex;
        //     justify-content: space-between;
        //     flex-wrap: wrap;
        //     &:first-child{
        //         width: 60%;
        //     }
        //     &:last-child{
        //         width: 38%;
        //     }
        // }
    }

    .tableContent {
        padding: 12px 17px;

        h3 {
            font-size: 14px;
            line-height: 19px;
            text-transform: capitalize;
            color: #0A1629;
        }

        .poTable {
            thead {
                tr {
                    th {
                        font-size: 12px;
                        line-height: 16px;
                        text-transform: capitalize;
                        color: #4D5E80;
                        background: #E0E4EC;
                        height: auto;
                        padding: 8px 15px;

                        &::before {
                            display: none;
                        }

                        &:first-child {
                            border-radius: 3px 0 0 0;
                        }

                        &:last-child {
                            border-radius: 0 3px 0 0;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        padding: 8px 15px;
                        font-size: 14px;
                        line-height: 19px;
                        text-transform: capitalize;
                        color: #4D5E80;
                        border-bottom: 1px solid #E4E5E9;

                        &:first-child {
                            border-left: 1px solid #E4E5E9;
                        }

                        &:last-child {
                            border-right: 1px solid #E4E5E9;
                        }
                    }
                }
            }
        }

    }
   
    // .ant-table-body {
    //     &::-webkit-scrollbar {
    //         width: 12px;
    //         height: 12px;
    //     }

    //     &::-webkit-scrollbar-thumb {
    //         background: #7D8FB3;
    //         border: 1px solid white;
    //         border-radius: 4px;
    //     }

    //     &::-webkit-scrollbar-track {
    //         // border: 1px solid #E4E5E9;
    //         border-radius: 2px;
    //         background: #FFFFFF;
    //     }
    //     &::-webkit-scrollbar-button:horizontal:start {
    //         width: 12px;
    //         height: 12px;
    //         background: url(../images/navigation/Angle_left.png) no-repeat center center;
    //     }
        
    //     &::-webkit-scrollbar-button:horizontal:end {
    //         width: 12px;
    //         height: 12px;
    //         background: url(../images/navigation/Angle_right.png) no-repeat center center;
    //     }
    //     &::-webkit-scrollbar-button:vertical:start {
    //         width: 12px;
    //         height: 12px;
    //         background: url(../images/navigation/Angle_top.png) no-repeat center center;
    //     }

    //     &::-webkit-scrollbar-button:vertical:end {
    //         width: 12px;
    //         height: 12px;
    //         background: url(../images/navigation/Angle_button.png) no-repeat center center;
    //     }

    // }



    .inspection_result {
        padding: 12px 20px;
        background: #F9FAFB;

        input,
        textarea {
            font-size: 14px;
            line-height: 19px;
            color: #5C7CEA;
        }

        .result {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h3 {
                font-size: 20px;
                line-height: 27px;
                color: #0A1629;
            }

            ul {
                display: flex;

                li {
                    padding: 8px 17px;
                    font-size: 16px;
                    line-height: 19px;
                    text-transform: capitalize;
                    color: #FFFFFF;
                    border-radius: 3px;
                    display: flex;
                    align-items: center;
                    // cursor: pointer;

                    img {
                        margin-right: 6px;
                    }
                }

                .Pass {
                    background: #91C561;
                }

                .Pending {
                    background: #3B82F6;
                    margin: 0 8px;
                }

                .Fail {
                    background: #EB4444;
                }
            }
        }

        h5 {
            font-size: 14px;
            line-height: 19px;
            color: #4D5E80;
        }

        .failure_code {
            li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 5px;

                .ant-form-item {
                    width: 91%;
                    margin-bottom: 0;
                }

                input {
                    width: 100%;
                }
            }
        }
        .Conditiona_lPass{
            display: flex;
            justify-content: flex-end;
            font-size: 30px;
        }
    }

    .ant-checkbox,
    .ant-checkbox-inner {
        width: 20px;
        height: 20px;
    }

    .ant-checkbox-inner::after {
        left: 29%;
    }
    .submitData {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 17px;
        padding: 0 20px;
        span{
            display: flex;
            align-items: center;
        }
        button {
            border-radius: 4px;
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            padding: 8px 16px;
            height: auto;
            display: flex;
            align-items: center;

            img {
                margin-right: 12px;
            }
        }

        li:first-child {
            display: flex;

            button {
                color: #4D5E80;
                margin-right: 8px;
            }
        }
        li:last-child{
            display: flex;
        }
    }
}
.verified{
    &>div{
        display: flex;
        h5{
            width: 100px;
        }
    }
}
.report {
    background: #FFFFFF;
    padding: 15px 20px;

    .reportTable {
        .ant-table-header {
            border-radius: 0;
        }

        thead {
            tr {
                th {
                    font-size: 14px;
                    line-height: 19px;
                    color: #FFFFFF;
                    text-transform: capitalize;
                    padding: 8px 16px;
                    background: #7D8FB3;
                    border-radius: 0 !important;
                    border: 1px solid #E4E5E9;

                    &::before {
                        display: none;
                    }
                }

                .ac {
                    background: #98C9A6;
                }

                .mi {
                    background: #E3BA7B;
                }

                .ma {
                    background: #E37B7B;
                }
                .pd{
                    background: #3B9CF6;
                }
            }
        }

        tbody {
            tr {
                td {
                    border: 1px solid #E4E5E9;
                }
            }
        }

        .comment {
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            color: #5C7CEA;
        }

        .reportTd {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .round {
                width: 24px;
                height: 24px;
                background: #FFFFFF;
                border: 1px solid #D9DDE0;
                border-radius: 50%;
                // cursor: pointer;
                cursor: no-drop;
            }
            .round_{
                width: 24px;
                height: 24px;
                background: #FFFFFF;
                border: 1px solid #D9DDE0;
                border-radius: 50%;
                cursor: pointer;
            }
        }

        .detail1 {
            color: #4D5E80;
            font-weight: 800;
            font-size: 14px;
        }

        .detail2 {
            color: #4D5E80;
            font-weight: 400;
            font-size: 14px;
        }
    }
}
.uploadImg{
    padding: 0 20px;
    display: flex;
    align-items: center;
    &>.icon{
        margin-left: 20px;
    }
}
.confimSubmit{
    .ant-modal-content{
        .ant-modal-body{
            padding: 0 20px !important;
        }
        
    }
    
}
.ConfirmationPassPopup{
    .ant-modal-title{
        padding: 0 18px;
    }
    .modal_content{
        max-height: 300px;
        overflow: auto;
    }
}
@media screen and(max-width:1350px) {
    .inspectionReport{
        .data_entry{
            &>div{
                // &:first-child{
                //     width: 45%;
                // }
                // &:last-child{
                //     width: 53%;
                // }
            }
        }
    }
}